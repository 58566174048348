<template>
  <div class="w-full relative">
    <component :is="currentComponent" teacher_id="teacher_id" :goodsList="goodsList" @imgClick="imgClick"
               @buyClick="buyClick" v-if="goodsList && goodsList.length > 0"></component>
    <div v-else>
      <van-empty description="没有数据"/>
    </div>
  </div>
</template>
<script>
import {Dialog, ImagePreview} from "vant";
import OriginGoodItem from "@/components/OriginGoodItem.vue";
import {getTeacherIdFromSessionStorage} from "@/util/getTeacherId";
import {getIndexPathFromStorage} from "@/util/getInitRoutePath";

export default {
  name: "allgoods",
  components: {OriginGoodItem},
  data() {
    return {
      bannerList: [],
      goodsList: [],
      teacher_id: getTeacherIdFromSessionStorage(),
      currentComponent: OriginGoodItem
    };
  },
  mounted() {
    let openid = this.$ls.get("user_openid");

    if (this.isWechat() && openid == null) {
      this.$router.push({
        name: "wxlogin",
        query: {
          teacher_id: this.teacher_id,
        },
      });
      // this.$toast("微信登录");
    }

    this.getInfo();
    this.getAllGoods();
  },
  methods: {
    isWechat() {
      return /MicroMessenger/i.test(window.navigator.userAgent);
    },

    async getInfo() {
      let res = await this.$axios.get(
          `/system/getBookPromoteSaleAds?teacher_id=${this.teacher_id || ''}`
      );
      if (res.code == 10001) {
        if (res.data && res.data.length > 0) {
          this.bannerList = res.data;
        }
      }
    },

    async getAllGoods() {
      let res = await this.$axios.get(`/item/getItemList?teacher_id=${this.teacher_id || ''}`);
      this.goodsList = res.data;
    },

    buyClick(item) {
      if (item.id === 25) {
        return this.$router.push({
          path: "/custom-product",
          query: {
            id: item.id
          }
        });
      }
      switch (item.jump_type) {
        case 1:
          this.$router.push({
            name: "pay",
            query: {
              id: item.id,
              teacher_id: this.teacher_id,
            },
          });
          break;

        case 2:
          window.location.href = item.jump_param;
          break;

        default:
          Dialog.alert({
            title: "商品跳转参数异常",
          }).then(() => {
            this.$router.replace(getIndexPathFromStorage());
          });
          break;
      }
    },

    imgClick(item) {
      ImagePreview({
        images: [item.img_url],
        closeable: true,
      });
    }
  },
};
</script>
<style scoped>

.desc-new {
  padding: 0.63rem 1.25rem;
}

.desc-new em {
  font-style: normal;
}

.desc-new span, .desc-new em {
  display: block;
}

.desc-new span:last-child {
  text-indent: 32px;
}

.info {
  background-color: #fafafa;
  margin-bottom: 1.25rem;
}

.title {
  font-weight: 500;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  font-size: 1.25rem !important;
}

.title p {
  font-size: 1.8rem !important;
}

.title p:last-child {
  font-size: 1.4rem !important;
}

.desc {
  padding: 0.63rem 1.25rem;
  text-indent: 32px;
}

.van-card__title {
  font-size: 1rem;
  line-height: 1.25rem !important;
  max-height: fit-content;
}

.van-card__origin-price {
  margin-left: 0.63rem;
  text-decoration-color: #f40;
}

.van-card {
  margin-top: 0 !important;
  padding: 1rem;
}

.tip {
  padding: 1rem 1rem 0;
  text-align: center;
  font-size: 0.88rem !important;
  color: #f7863f;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
  letter-spacing: 0.03rem;
  font-weight: 600;
}

.notice {
  padding: 1rem;
  overflow-y: auto;
}

.notice p {
  font-size: 1rem !important;
  color: #000;
  line-height: 2;
}

.notice p:last-child {
  margin-top: 0.5rem;
  text-align: center;
  color: #ee0a24;
  font-size: 1rem;
  font-weight: 600;
}

.use {
  width: inherit;
  text-align: center;
  position: fixed;
  bottom: 8.5vh;
}

.use .van-button {
  width: 90%;
  font-size: 1.13rem;
  font-weight: 900;
  letter-spacing: 0.3rem !important;
  opacity: 0.9;
}

@media screen and (min-width: 768px) {
  .notice {
    height: auto;
    overflow-y: auto;
  }

  .use {
    width: 50%;
  }
}
</style>
