import Vue from "vue";
import VueRouter from "vue-router";
import allgoods from "../views/allgoods.vue";
import user from "../views/user.vue";
import pay from "../views/buygoods.vue";
import login from "../views/login.vue";
import wxlogin from "../views/wxlogin.vue";
import test from "../views/57a88b7092a0d3f3af5629753e953abe.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: allgoods,
    },
    {
        path: "/user",
        name: "user",
        component: user,
    },
    {
        path: "/pay",
        name: "pay",
        component: pay,
    },
    {
        path: "/login",
        name: "login",
        component: login,
    },
    {
        path: "/wxlogin",
        name: "wxlogin",
        component: wxlogin,
    },
    {
        path: "/57a88b7092a0d3f3af5629753e953abe",
        name: "57a88b7092a0d3f3af5629753e953abe",
        component: test,
    },
    {
        path: "/custom-product",
        name: "custom-product",
        component: () => import("../views/custom-product.vue"),
        meta: {
            // hiddenBack: true,
            hiddenTabbar: true,
            hiddenHeader: true,
            title: "充值"
        }
    },
];

const festival_routes = [
    // 十周年门票售卖
    {
        path: "/decennial_ticket_sales",
        component: () => import("../festival-views/decennial_ticket_sales.vue")
    }
]

const default_routes = [
    {
        path: "*",
        redirect: "/",
        component: allgoods
    }
]

const calc_routes = [...routes, ...festival_routes, ...default_routes];

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes: calc_routes,
    mode: "history",
});

let whiteList = [
    "/login",
    "/",
    "/wxlogin"
]

// 活动页都加入白名单
const whit = festival_routes.map(item => item.path);
whiteList = [...whiteList, ...whit];

router.beforeEach((to, from, next) => {
    let user_id = Vue.prototype.$ls.get("user_id");
    if (user_id) {
        if (to.path === "/login") {
            next({path: "/"});
        } else {
            next();
        }
    } else {
        if (whiteList.includes(to.path)) {
            next();
        } else {
            next({path: "/login"});
        }
    }
})

// router.beforeEach((to, from, next) => {
//   let user_id = Vue.prototype.$ls.get("user_id");
//   let user_phone = Vue.prototype.$ls.get("user_phone");
//   let url =  new URLSearchParams(location.search);
//   // 获取地址栏teacher_id
//   let teacher_id = url.get("teacher_id");
//   let id = url.get("id");
//   let query = {};
//   if(teacher_id) {
//     query["teacher_id"] = teacher_id;
//   }
//   if(id) {
//     query['id'] = id;
//   }
//   if(user_id) {
//     if(to.path === "/login") {
//       next({path: "/", query});
//     }else {
//       next({query});
//     }
//   }else {
//     if(whiteList.includes(to.path)) {
//       next({query});
//     }else {
//       next({path: "/login", query});
//     }
//   }
// })

export default router;
