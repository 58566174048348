const initPathBlacklist = ["/login", "/wxlogin", "/user"];

export function getInitRoutePath() {
    const path = window.location.pathname;
    const search = window.location.search;
    let url = path + search;
    if (initPathBlacklist.includes(path)) {
        url = "/" + search;
    }
    sessionStorage.setItem("index-route-path", url);
    console.log("初始化首页地址为：", url);
}

export function getIndexPathFromStorage() {
    return sessionStorage.getItem("index-route-path") || "/";
}