export function getTeacherId() {
    let urlSearch = new URLSearchParams(window.location.search);
    let teacher_id = urlSearch.get("teacher_id") || "";
    let id = urlSearch.get("id") || "";
    console.log("初始化获取到的teacher_id=" + teacher_id);
    sessionStorage.setItem('teacher_id', teacher_id);
    sessionStorage.setItem('id', id);
}

export function getTeacherIdFromSessionStorage() {
    return sessionStorage.getItem('teacher_id') || undefined;
}

export function getIdFromSessionStorage() {
    return sessionStorage.getItem('id') || undefined;
}